define('ember-infinity/mixins/route', ['exports', 'ember-infinity/lib/infinity-model', 'ember-infinity/lib/infinity-promise-array', 'ember-infinity/mixins/bound-params', 'ember-infinity/utils'], function (exports, _infinityModel, _infinityPromiseArray, _boundParams, _utils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  /**
    The Ember Infinity Route Mixin enables an application route to load paginated
    records for the route `model` as triggered by the controller (or Infinity Loader
    component).
  
    @class RouteMixin
    @namespace EmberInfinity
    @module ember-infinity/mixins/route
    @extends Ember.Mixin
  */
  var RouteMixin = Ember.Mixin.create({

    // these are here for backwards compat
    _infinityModel: Ember.computed('_infinityModels.[]', function () {
      return Ember.get(this, '_infinityModels.firstObject');
    }).readOnly(),
    currentPage: Ember.computed.alias('_infinityModel.currentPage').readOnly(),

    actions: {
      infinityLoad: function infinityLoad(infinityModel) {
        var matchingInfinityModel = this._infinityModels.find(function (model) {
          return model === infinityModel;
        });
        if (matchingInfinityModel) {
          this._infinityLoad(matchingInfinityModel);
        } else {
          return true;
        }
      }
    },
    /**
      @private
      @property _store
      @type String
      @default 'store'
    */
    _store: 'store',

    /**
      The supported findMethod name for
      the developers Ember Data version.
      Provided here for backwards compat.
      @private
      @property _storeFindMethod
      @type {String}
      @default "query"
     */
    _storeFindMethod: 'query',

    _ensureCompatibility: function _ensureCompatibility() {
      if (Ember.isEmpty(this.get(this._store)) || Ember.isEmpty(this.get(this._store)[this._storeFindMethod])) {
        throw new Ember.Error("Ember Infinity: Store is not available to infinityModel");
      }
    },
    _ensureCustomStoreCompatibility: function _ensureCustomStoreCompatibility(options) {
      if (typeof options.store !== 'string') {
        throw new Ember.Error("Ember Infinity: Must pass custom data store as a string");
      }

      var store = this.get(options.store);
      if (!store[this.get('_storeFindMethod')]) {
        throw new Ember.Error("Ember Infinity: Custom data store must specify query method");
      }
    },
    infinityModel: function infinityModel(modelName, options, boundParamsOrInfinityModel) {

      var boundParams = void 0,
          ExtendedInfinityModel = void 0;
      if (Ember.typeOf(boundParamsOrInfinityModel) === "class") {
        if (!(boundParamsOrInfinityModel.prototype instanceof _infinityModel.default)) {
          throw new Ember.Error("Ember Infinity: You must pass an Infinity Model instance as the third argument");
        }
        ExtendedInfinityModel = boundParamsOrInfinityModel;
      } else if (Ember.typeOf(boundParamsOrInfinityModel) === "object") {
        boundParams = boundParamsOrInfinityModel;
      }

      if (modelName === undefined) {
        throw new Ember.Error("Ember Infinity: You must pass a Model Name to infinityModel");
      }

      if (!this._infinityModels) {
        this._infinityModels = Ember.A();
      }

      options = options ? (0, _utils.objectAssign)({}, options) : {};

      if (options.store) {
        if (options.storeFindMethod) {
          this.set('_storeFindMethod', options.storeFindMethod);
        }

        this._ensureCustomStoreCompatibility(options);

        this.set('_store', options.store);

        delete options.store;
        delete options.storeFindMethod;
      }

      var currentPage = options.startingPage === undefined ? 0 : options.startingPage - 1;
      var perPage = options.perPage || 25;

      // check if user passed in param w/ infinityModel, else check if defined on the route (for backwards compat), else default
      var perPageParam = (0, _utils.paramsCheck)(options.perPageParam, Ember.get(this, 'perPageParam'), 'per_page');
      var pageParam = (0, _utils.paramsCheck)(options.pageParam, Ember.get(this, 'pageParam'), 'page');
      var totalPagesParam = (0, _utils.paramsCheck)(options.totalPagesParam, Ember.get(this, 'totalPagesParam'), 'meta.total_pages');

      delete options.startingPage;
      delete options.perPage;
      delete options.perPageParam;
      delete options.pageParam;
      delete options.totalPagesParam;

      var InfinityModelFactory = void 0;
      var didPassBoundParams = !Ember.isEmpty(boundParams);
      if (didPassBoundParams) {
        // if pass boundParamsOrInfinityModel, send to backwards compatible mixin that sets bound params on route
        // and subsequently looked up when user wants to load next page
        InfinityModelFactory = _infinityModel.default.extend(_boundParams.default);
      } else if (ExtendedInfinityModel) {
        // if custom InfinityModel, then use as base for creating an instance
        InfinityModelFactory = ExtendedInfinityModel;
      } else {
        InfinityModelFactory = _infinityModel.default;
      }

      var initParams = {
        currentPage: currentPage,
        perPage: perPage,
        perPageParam: perPageParam,
        pageParam: pageParam,
        totalPagesParam: totalPagesParam,
        _infinityModelName: modelName,
        extraParams: options,
        content: Ember.A()
      };

      if (didPassBoundParams) {
        initParams._deprecatedBoundParams = boundParams;
        initParams.route = this;
      }

      var infinityModel = InfinityModelFactory.create(initParams);
      this._ensureCompatibility();
      this._infinityModels.pushObject(infinityModel);

      return _infinityPromiseArray.default.create({ promise: this._loadNextPage(infinityModel) });
    },
    updateInfinityModel: function updateInfinityModel(newObjects) {
      Ember.deprecate('Ember Infinity: this method will be deprecated in the future.', false, {
        id: 'ember-infinity',
        until: '1.0.0'
      });
      return this._doUpdate(newObjects);
    },
    _afterInfinityModel: function _afterInfinityModel(_this) {
      return function (infinityModelPromiseResult, infinityModel) {
        if (typeof _this.afterInfinityModel === 'function') {
          var result = _this.afterInfinityModel(infinityModelPromiseResult, infinityModel);
          if (result) {
            return result;
          }
        }

        return infinityModelPromiseResult;
      };
    },
    _infinityLoad: function _infinityLoad(infinityModel) {
      if (Ember.get(infinityModel, '_loadingMore') || !Ember.get(infinityModel, '_canLoadMore')) {
        return;
      }

      this._loadNextPage(infinityModel);
    },
    _loadNextPage: function _loadNextPage(infinityModel) {
      var _this2 = this;

      Ember.set(infinityModel, '_loadingMore', true);

      var modelName = Ember.get(infinityModel, '_infinityModelName');
      var params = infinityModel.buildParams();

      return this._requestNextPage(modelName, params).then(function (newObjects) {
        return _this2._afterInfinityModel(_this2)(newObjects, infinityModel);
      }).then(function (newObjects) {
        return _this2._doUpdate(newObjects, infinityModel);
      }).then(function (infinityModel) {
        infinityModel.incrementProperty('currentPage');
        Ember.set(infinityModel, '_firstPageLoaded', true);
        var canLoadMore = Ember.get(infinityModel, '_canLoadMore');
        Ember.set(infinityModel, 'reachedInfinity', !canLoadMore);
        if (!canLoadMore) {
          _this2._notifyInfinityModelLoaded();
        }
        return infinityModel;
      }).finally(function () {
        return Ember.set(infinityModel, '_loadingMore', false);
      });
    },
    _requestNextPage: function _requestNextPage(modelName, params) {
      return this.get(this._store)[this._storeFindMethod](modelName, params);
    },
    _doUpdate: function _doUpdate(queryObject, infinityModel) {
      var totalPages = queryObject.get(Ember.get(infinityModel, 'totalPagesParam'));
      Ember.set(infinityModel, '_totalPages', totalPages);
      Ember.set(infinityModel, 'meta', Ember.get(queryObject, 'meta'));
      return infinityModel.pushObjects(queryObject.toArray());
    },
    _notifyInfinityModelUpdated: function _notifyInfinityModelUpdated(newObjects) {
      if (!this.infinityModelUpdated) {
        return;
      }

      Ember.deprecate('Ember Infinity: infinityModelUpdated will be deprecated in the future.', false, {
        id: 'ember-infinity',
        until: '1.0.0'
      });
      Ember.run.scheduleOnce('afterRender', this, 'infinityModelUpdated', {
        lastPageLoaded: this.get('currentPage'),
        totalPages: this.get('_totalPages'),
        newObjects: newObjects
      });
    },
    _notifyInfinityModelLoaded: function _notifyInfinityModelLoaded() {
      if (!this.infinityModelLoaded) {
        return;
      }

      var totalPages = this.get('_totalPages');
      Ember.run.scheduleOnce('afterRender', this, 'infinityModelLoaded', { totalPages: totalPages });
    }
  });

  exports.default = RouteMixin;
});