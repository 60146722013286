define('ember-cli-gravatar/components/has-gravatar', ['exports', 'ember', 'ember-cli-gravatar/templates/components/has-gravatar'], function (exports, _ember, _emberCliGravatarTemplatesComponentsHasGravatar) {
  var Component = _ember['default'].Component;
  var getProperties = _ember['default'].getProperties;
  var service = _ember['default'].inject.service;
  var set = _ember['default'].set;
  exports['default'] = Component.extend({
    gravatar: service(),

    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      this._super.apply(this, arguments);

      var _getProperties = getProperties(this, 'email', 'gravatar', 'secure');

      var email = _getProperties.email;
      var gravatar = _getProperties.gravatar;
      var secure = _getProperties.secure;

      return gravatar.hasGravatar(email, secure).then(function (hasGravatar) {
        set(_this, 'hasGravatar', hasGravatar);
        set(_this, 'checking', false);
      });
    },

    layout: _emberCliGravatarTemplatesComponentsHasGravatar['default'],
    hasGravatar: false,
    checking: true,
    email: '',
    secure: true
  });
});