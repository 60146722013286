define('ember-infinity/mixins/bound-params', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    route: null,
    _deprecatedBoundParams: null,

    /**
      get bound param off of route and include in params
       @method buildParams
      @return {Object}
     */
    buildParams: function buildParams() {
      var _this = this;

      Ember.deprecate("Ember Infinity: Bound params are now deprecated. Please pass explicitly as second param to the infinityModel method", false, {
        id: 'ember-infinity',
        until: '1.0.0'
      });

      var params = this._super.apply(this, arguments);
      var boundParams = Ember.get(this, '_deprecatedBoundParams');

      if (!Ember.isEmpty(boundParams)) {
        Object.keys(boundParams).forEach(function (k) {
          return params[k] = Ember.get(_this, 'route.' + boundParams[k]);
        });
      }

      return params;
    }
  });
});