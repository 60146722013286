define('ember-infinity/components/infinity-loader', ['exports', 'ember-infinity/lib/infinity-promise-array', 'ember-in-viewport'], function (exports, _infinityPromiseArray, _emberInViewport) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var InfinityLoaderComponent = Ember.Component.extend(_emberInViewport.default, {
    classNames: ["infinity-loader"],
    classNameBindings: ["infinityModelContent.reachedInfinity", "viewportEntered:in-viewport"],
    eventDebounce: 50,
    loadMoreAction: 'infinityLoad',
    loadingText: 'Loading Infinite Model...',
    loadedText: 'Infinite Model Entirely Loaded.',
    hideOnInfinity: false,
    developmentMode: false,
    scrollable: null,
    triggerOffset: 0,
    isVisible: true,

    willInsertElement: function willInsertElement() {
      if (this.get('_isInfinityPromiseArray')) {
        Ember.defineProperty(this, 'infinityModelContent', Ember.computed.alias('infinityModel.content'));
      } else {
        Ember.defineProperty(this, 'infinityModelContent', Ember.computed.alias('infinityModel'));
      }
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.setProperties({
        viewportSpy: true,
        viewportTolerance: {
          top: 0,
          right: 0,
          bottom: this.get('triggerOffset'),
          left: 0
        },
        scrollableArea: this.get('scrollable')
      });
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this._cancelTimers();
    },


    _isInfinityPromiseArray: Ember.computed('infinityModel', function () {
      return this.get('infinityModel') instanceof _infinityPromiseArray.default;
    }),

    didEnterViewport: function didEnterViewport() {
      var consideredReady = !this.get('_isInfinityPromiseArray') || this.get('infinityModel.isFulfilled');
      if (this.get('developmentMode') || typeof FastBoot !== 'undefined' || this.isDestroying || this.isDestroyed || !consideredReady) {
        return false;
      }

      this._debounceScrolledToBottom();
    },
    didExitViewport: function didExitViewport() {
      this._cancelTimers();
    },


    /**
     * @method loadedStatusDidChange
     */
    loadedStatusDidChange: Ember.observer('infinityModelContent.reachedInfinity', 'hideOnInfinity', function () {
      if (this.get('infinityModelContent.reachedInfinity') && this.get('hideOnInfinity')) {
        this.set('isVisible', false);
      }
    }),

    _debounceScrolledToBottom: function _debounceScrolledToBottom() {
      /*
       This debounce is needed when there is not enough delay between onScrolledToBottom calls.
       Without this debounce, all rows will be rendered causing immense performance problems
       */
      function loadMore() {
        this.sendAction('loadMoreAction', this.get('infinityModelContent'));
      }
      this._debounceTimer = Ember.run.debounce(this, loadMore, this.get('eventDebounce'));
    },
    _cancelTimers: function _cancelTimers() {
      Ember.run.cancel(this._debounceTimer);
    }
  });

  exports.default = InfinityLoaderComponent;
});